<template>
  <div>
    <div @click="to">编辑</div>
    <eForm
        ref="form"
        :sup_this="sup_this"
        :is-edit="true"
    />
  </div>
</template>
<script>
import { getGuestList } from '@/api/cus/tCustInfo'
import eForm from './form'
import { initData } from '@/api/data';
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      viewData:{},
    };
  },
  methods: {
    getCustLeveList(custId){
      getGuestList({
        custId:custId
      }).then((res) => {
        this.$refs.form.custLevelList = res;
      })
    },
    to() {
      this.$refs.form.levelName = this.data.levelName;
      this.getCustLeveList(this.data.belongId);
      const url = 'crm/tCustInfo';
      const sort = 'id,desc';
      const params = { basicInfo:this.data.id,page: 0, size: 10, sort: sort ,flag:'false'}
      initData(url, params).then(res => {
          this.viewData = res.content[0];
        const _this = this.$refs.form;
        _this.lockLevel = this.viewData.modifyLevelFlag;
        _this.form = {
          linkId: this.viewData.linkId,
          id: this.viewData.id,
          sectorCode: this.viewData.sectorCode,
          belongId: this.viewData.belongId,
          custType: this.viewData.custType,
          custSource: this.viewData.custSource,
          ibRank: this.viewData.ibRank,
          custLvl: this.viewData.custLvl,
          custLvlComment: this.viewData.custLvlComment,
          custStatus: this.viewData.custStatus,
          auditStatus: this.viewData.auditStatus,
          applyDate: this.viewData.applyDate,
          mt4MainAcc: this.viewData.mt4MainAcc,
          firstName: this.viewData.firstName,
          middlName: this.viewData.middlName,
          lastName: this.viewData.lastName,
          userName: this.viewData.userName,
          password: this.viewData.password,
          gender: this.viewData.gender,
          certType: this.viewData.certType,
          certId: this.viewData.certId,
          certValidatedImgFront: this.viewData.certValidatedImgFront,
          certValidatedImgBack: this.viewData.certValidatedImgBack,
          email: this.viewData.email,
          phone: this.viewData.phone,
          birthday: this.viewData.birthday,
          telephone: this.viewData.telephone,
          openAccountFlag: this.viewData.openAccountFlag,
          openLeverage: this.viewData.openLeverage,
          riskControlFlag: this.viewData.riskControlFlag || 0, // 默认为不限制
          addLevelFlag: this.viewData.addLevelFlag, // 默认为允许


          imAcc: this.viewData.imAcc,
          website: this.viewData.website,
          rewardPointFlag: this.viewData.rewardPointFlag,// 交易积分 统不统计
          nationality: this.viewData.nationality,
          homeCountryCode: this.viewData.homeCountryCode,
          homeProvince: this.viewData.homeProvince,
          homeCity: this.viewData.homeCity,
          homeStreet: this.viewData.homeStreet,
          homeAddress: this.viewData.homeAddress,
          homePostcode: this.viewData.homePostcode,
          workCountryCode: this.viewData.workCountryCode,
          workProvince: this.viewData.workProvince,
          workCity: this.viewData.workCity,
          workStreet: this.viewData.workStreet,
          workAddress: this.viewData.workAddress,
          workPostcode: this.viewData.workPostcode,
          addressImg: this.viewData.addressImg,
          bossFullname: this.viewData.bossFullname,
          title: this.viewData.title,
          industry: this.viewData.industry,
          tradingAges: this.viewData.tradingAges,
          investExperienceYear: this.viewData.investExperienceYear,
          agentAges: this.viewData.agentAges,
          annualIncome: this.viewData.annualIncome,
          fundsSource: this.viewData.fundsSource,
          havingClients: this.viewData.havingClients,
          source: this.viewData.source,
          otherSource: this.viewData.otherSource,
          bankOutletProvince: this.viewData.bankOutletProvince,
          bankOutletCity: this.viewData.bankOutletCity,

          oldOrgImg: this.viewData.oldOrgImg,
          oldFundImg: this.viewData.oldFundImg,
          oldDepositImg: this.viewData.oldDepositImg,
          oldClientImg: this.viewData.oldClientImg,
          oldTradeImg: this.viewData.oldTradeImg,
          developArea: this.viewData.developArea,
          linkForChild: this.viewData.linkForChild,
          qrForChild: this.viewData.qrForChild,
          linkForCust: this.viewData.linkForCust,
          qrForCust: this.viewData.qrForCust,
          remark: this.viewData.remark,
          hierarchy: this.viewData.hierarchy,
        }
        for (let i = 0; i < this.viewData.collections.length; i++) {
          if(this.viewData.collections[i].payTypeId ==4){
            if(this.viewData.collections[i].custPayDetail){
              _this.form.bankName = this.viewData.collections[i].custPayDetail.bankName;
              _this.form.bankAccount = this.viewData.collections[i].custPayDetail.bankAccount;
              _this.form.swiftCode = this.viewData.collections[i].custPayDetail.swiftCode;
              _this.form.bankFrontImg = this.viewData.collections[i].custPayDetail.bankFrontImg;
              _this.form.bankBackImg = this.viewData.collections[i].custPayDetail.bankBackImg;
              _this.form.bankOutletProvince = this.viewData.collections[i].custPayDetail.bankOutletProvince;
              _this.form.bankOutletCity = this.viewData.collections[i].custPayDetail.bankOutletCity;
              _this.form.bankOutlet = this.viewData.collections[i].custPayDetail.bankOutlet;
            }
          }
          if(this.viewData.collections[i].payTypeId ==5){
            if(this.viewData.collections[i].custPayDetail){
              _this.form.usdtQrPic = this.viewData.collections[i].custPayDetail.qrPic;
              _this.form.usdtAdress = this.viewData.collections[i].custPayDetail.address;
            }
          }
          if(this.viewData.collections[i].payTypeId == 6){
            if(this.viewData.collections[i].custPayDetail){
              _this.form.otctQrPic = this.viewData.collections[i].custPayDetail.qrPic;
              _this.form.otcAdress = this.viewData.collections[i].custPayDetail.address;
            }
          }
        }

          if( this.viewData.custType == 3) {
            _this.isIb = false;
            _this.form.linkId = this.data.linkId;
          }else {
            _this.isIb = true;
          }
          _this.dialog = true;
        }).catch(err => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>

<template>
  <div class="head-container">
    <!-- 搜索 -->
     <el-input
             v-model="query.mt4Info"
             clearable
             placeholder="MT4分组/账户"
             style="width: 150px;"
             class="filter-item"
             @keyup.enter.native="toQuery"
     />
     <el-input
             v-model="query.custInfo"
             clearable
             placeholder="客户姓名/ID/邮箱"
             style="width: 180px;"
             class="filter-item"
             @keyup.enter.native="toQuery"/>
      <el-input
         v-model="query.agentInfo"
         clearable
         placeholder="代理姓名/ID"
         style="width: 150px;"
         class="filter-item"
         @keyup.enter.native="toQuery"
     />
     <el-input 
        v-model="query.manageInfo" 
        clearable 
        placeholder="客户经理姓名/ID" 
        style="width: 150px;" 
        class="filter-item" 
        @keyup.enter.native="toQuery"/>
     <div class="filter-item">
            统计时间：
            <el-date-picker
                    v-model="startTime"
                    type="date"
                    placeholder="开始日期"
            >
            </el-date-picker>
            至
            <el-date-picker
                    v-model="endTime"
                    type="date"
                    placeholder="结束日期"
            >
            </el-date-picker>
        </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { init_date } from '@/utils/index'
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      startTime: '',
      endTime: '',
    }
  },
  created(){
    this.initDate();
  },
  methods: {
    checkPermission,
    toQuery() {
      this.query.endTime = this.endTime;
      this.query.startTime = this.startTime;
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    },
    initDate(){
      let dateInfo = init_date();
      this.endTime = dateInfo.today;
      this.startTime = dateInfo.firstday;
    },
  }
}
</script>
<style lang="less">
</style>
